<template>
  <main :class="$style.section">
    <div :class="$style.wrapper">
      <icon :class="$style.logo" name="logo" />
      <router-link to="/" :class="[$style.link, $style.linkLogo]">
        <icon :class="$style.linkLogoIcon" name="left" />
        <span :class="$style.linkLogoText">{{ $t('rules.toMain') }}</span>
      </router-link>
    </div>
    <p :class="[$style.word, $style.wordTop]">404</p>
    <div :class="$style.content">
      <h1 :class="$style.title">{{ $t('notFound.title') }}</h1>
      <p :class="$style.text" v-html="$t('notFound.text')" />
      <v-button :class="$style.button" to="/">{{ $t('notFound.goBack') }}</v-button>
    </div>
    <p :class="[$style.word, $style.wordBottom]">404</p>
    <div :class="$style.wrapper">
      <p :class="[$style.link, $style.date]">{{ `© Supster ${new Date().getFullYear()} ` }}</p>
      <a :class="$style.link" :href="`${rulseforuse}?locale=${locale}`" target="_blank">{{
        $t('rules.title')
      }}</a>
    </div>
  </main>
</template>
<script>
import VButton from '@/components/VButton.vue'
import i18n from '@/plugins/i18n'

export default {
  components: { VButton },
  name: 'NotFound',
  computed: {
    rulseforuse() {
      return process.env.VUE_APP_RULESFORUSE
    },
    locale() {
      return i18n.locale
    },
  },
}
</script>
<style lang="scss" module>
.section {
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100vw;
  height: 100vh !important;
  padding: $padding * 1.5;
  overflow: hidden;
  background-color: $primary;

  @include media('mobile') {
    padding: $padding;
  }
}

.wrapper {
  display: grid;
  grid-auto-flow: column;
  align-self: center;
  justify-content: space-between;
}

.logo {
  width: 92px;
  height: 28px;
  color: $secondary;
}

.link {
  position: relative;
  z-index: 2;
  align-self: center;
  color: $secondary;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;

  &:hover {
    opacity: 0.7;

    svg {
      transform: translateX(-5px);
    }
  }

  &Logo {
    display: grid;
    grid-template-columns: 24px auto;
    column-gap: 8px;

    &Icon {
      align-self: center;
      width: 24px;
      height: 4px;
      margin-top: -2px;
      color: white;
      transition: transform $transition;
    }

    &Text {
      align-self: center;
      color: $secondary;
    }
  }
}

.date {
  color: white;

  &:hover {
    opacity: 1;
  }
}

.content {
  display: grid;
  align-self: center;
  justify-self: center;
  row-gap: 32px;
}

.title {
  position: relative;
  z-index: 2;
  color: white;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
}

.text {
  position: relative;
  z-index: 2;
  color: white;
  font-size: 16px;
  line-height: 25px;
  text-align: center;

  @include media('mobile') {
    font-size: 12px;
    line-height: 18px;
  }
}

.button {
  position: relative;
  z-index: 2;
  font-size: 16px;
  font-weight: 700;
  max-width: 144px;
  height: 40px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.word {
  position: fixed;
  z-index: 1;
  color: rgba(255, 255, 255, 0.1);
  font-weight: 900;
  font-size: 600px;
  line-height: 600px;
  transform: rotate(-45deg);

  @media screen and (max-width: 1300px) {
    font-size: 66.6vh;
    line-height: 66.6vh;
  }

  @media screen and (max-width: 940px) {
    font-size: 46.154vw;
    line-height: 46.154vw;
  }

  &Top {
    top: -7px;
    left: -227px;

    @media screen and (max-width: 1300px) {
      top: -0.538vw;
      left: -17.462vw;
    }

    @media screen and (max-width: 940px) {
      top: 6vh;
      left: -6.2vh;
    }
  }

  &Bottom {
    right: -165px;
    bottom: 0;

    @media screen and (max-width: 1300px) {
      right: -12.692vw;
    }

    @media screen and (max-width: 940px) {
      right: -6.3vh;
      bottom: 6vh;
    }
  }
}
</style>
